import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import {
  Box,
  Card,
  CardMedia,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const MediaWithTranscription = ({
  urlAudio,
  urlVideo,
  transcriptionJSON,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mediaRef = useRef(null);
  const textRefs = useRef([]);
  const manualScroll = useRef(false);
  const [currTime, setCurrTime] = useState(0);
  const parseTextJson = JSON.parse(transcriptionJSON);

  let manualScrollTimeout = null;
  const handleManualScroll = () => {
    clearTimeout(manualScrollTimeout);
    manualScroll.current = true;
    manualScrollTimeout = setTimeout(() => {
      manualScroll.current = false;
    }, 3000);
  };

  useEffect(() => {
    if (mediaRef.current) {
      document.getElementById('transcription').addEventListener('wheel', handleManualScroll);
    }
    return () => {
      if (document.getElementById('transcription')) {
        document.getElementById('transcription').removeEventListener('wheel', handleManualScroll);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaRef]);

  const handleTranscriptClick = (time) => {
    if (mediaRef.current) {
      manualScroll.current = false;
      mediaRef.current.currentTime = time;
      mediaRef.current.play();
    }
  };

  const handleTimeUpdate = () => {
    if (mediaRef.current && !manualScroll.current) {
      const { currentTime } = mediaRef.current;
      setCurrTime(currentTime);

      // Trouver le texte associé au moment actuel
      const activeItemIndex = parseTextJson.findIndex((item, index) => {
        const nextItem = parseTextJson[index + 1];
        return currentTime >= parseFloat(item.start) && (!nextItem || currentTime < parseFloat(nextItem.start));
      });

      // Faire défiler vers le texte actif
      if (activeItemIndex !== -1 && textRefs.current[activeItemIndex]) {
        textRefs.current[activeItemIndex].scrollIntoView({ behavior: 'instant' });
      }
    }
  };

  function formatTime(seconds) {
    const milliseconds = seconds * 1000;
    // Calcul des heures, minutes et secondes
    const heures = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const secondes = Math.floor((milliseconds % (1000 * 60)) / 1000);

    // Ajouter des zéros devant si nécessaire
    const heuresStr = heures.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    const secondesStr = secondes.toString().padStart(2, '0');

    // Retourner le temps formaté
    return `${heuresStr}:${minutesStr}:${secondesStr}`;
  }

  return (
    <Fragment>
      {urlVideo && (
        <Card
          sx={{
            maxWidth: '500px',
            mt: 2,
            mb: 2,
            flexShrink: 0,
          }}
        >
          <CardMedia component="video" controls src={urlVideo} ref={mediaRef} onTimeUpdate={handleTimeUpdate} />
        </Card>
      )}
      {urlAudio && (
        <CardMedia
          sx={{
            maxWidth: '500px',
            mt: 2,
            mb: 2,
            flexShrink: 0,
          }}
          component="audio"
          controls
          onTimeUpdate={handleTimeUpdate}
          src={urlAudio}
          ref={mediaRef}
        />
      )}
      <Box
        mt={2}
        whiteSpace="pre-wrap"
        lineHeight="30px"
        sx={{ clear: 'both' }}
        overflow="auto"
        id="transcription"
      >
        {_.map(
          parseTextJson,
          (item, index) => (
            <Box
              key={index}
              mt={2}
              ref={(el) => { textRefs.current[index] = el; }}
            >
              <Box
                display="flex"
                flexWrap="wrap"
                mb={0.5}
              >
                <Box>
                  {t('document.time')}
                  <Box
                    component="span"
                    cursor="pointer"
                    sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
                    onClick={() => handleTranscriptClick(item.start)}
                  >
                    {formatTime(item.start)}
                  </Box>
                </Box>
                <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
                <Box>
                  {t('document.speaker')}
                  {item.spkid}
                </Box>
              </Box>
              {_.isEmpty(item.words) ? (
                <Box sx={{ '&::first-letter': { textTransform: 'uppercase' } }}>{item.sentence.trim()}</Box>
              ) : (
                _.map(
                  item.words,
                  (word) => {
                    let wordColor = theme.palette.text.primary;
                    if (+word.confidence < 0.5) {
                      wordColor = theme.palette.text.error;
                    } else if (+word.confidence > 1) {
                      wordColor = theme.palette.text.success;
                    }
                    return (
                      <Box
                        component="span"
                        key={word.start}
                        sx={{
                          color: wordColor,
                          opacity: currTime > +word.start ? 1 : 0.5,
                        }}
                        onClick={() => handleTranscriptClick(word.start)}
                      >
                        {`${word.word.trim()} `}
                      </Box>
                    );
                  },
                )
              )}
            </Box>
          ),
        )}
      </Box>
    </Fragment>
  );
};

MediaWithTranscription.propTypes = {
  urlAudio: PropTypes.string,
  urlVideo: PropTypes.string,
  transcriptionJSON: PropTypes.string,
};

MediaWithTranscription.defaultProps = {
  urlAudio: null,
  urlVideo: null,
  transcriptionJSON: [],
};

export default MediaWithTranscription;
